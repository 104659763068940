import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React from "react";
import { Outlet } from "react-router";
import ProtectedRoute from "../__AppRouter__/ProtectedRoute";

export function DataPage() {
  return (
    <>
      <ProtectedRoute>
        <Layout>
            <Header></Header>
          <Layout>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </ProtectedRoute>
    </>
  );
}
