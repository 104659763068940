import React from "react";
import { MenuProps } from "antd";

export type MenuItem = Required<MenuProps>["items"][number];

export function getMenuItem({
  id,
  label,
  key,
  icon,
  children,
  type,
  onClick,
  onDoubleClick,
}: {
  id?: string;
  label: React.ReactElement | string;
  key: React.Key;
  icon?: React.ReactNode;
  children?: any[];
  type?: "group";
  onClick?: () => void;
  onDoubleClick?: () => void;
}): MenuItem {
  return {
    id,
    key,
    icon,
    children,
    label: <span className="no-text-select">{label}</span>,
    type,
    title: null,
    onClick: onClick,
    onDoubleClick: onDoubleClick,
  } as MenuItem;
}
