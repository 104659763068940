import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { get_translation_data } from "./translations";
export let activeLanguage = 'en';



export function get_browser_language() {
    try {
        return window.navigator.language.split('-')[0]
    } catch (e) {
        return 'en'
    }

}


export function init_i18n() {    
    i18n
        .use(LanguageDetector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: get_translation_data(),
            fallbackLng: "en",
            interpolation: {
                escapeValue: false
            }
        });
}

export default i18n;
