import { AxiosInstance } from "axios";
import {
    getAccessToken,
    getRefreshToken,
    setAccessTokenCache,
    setRefreshTokenCache
} from "../Auth";
import { BACKEND_API } from "../Params";
import { REQUEST_TIMEOUT_DEFAULT, getAxiosInstance } from "../utils/axios_instance";

export const AXIOS_TIMEOUT_DEFAULT = 60 * 1000; // 60 seconds
let MAIN_AXIOS_INSTANCE: AxiosInstance;

export function getMainAxiosInstance() {
  if (!MAIN_AXIOS_INSTANCE) {
    MAIN_AXIOS_INSTANCE = getAxiosInstance({
      baseURL: BACKEND_API,
      timeout: REQUEST_TIMEOUT_DEFAULT,
      getAccessToken: getAccessToken,
      getRefreshToken: getRefreshToken,
      setAccessToken: setAccessTokenCache,
      setRefreshToken: setRefreshTokenCache,
    });
  }
  return MAIN_AXIOS_INSTANCE;
}

export async function axios_config_init() {
  getMainAxiosInstance();
}
