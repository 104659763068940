import React from "react";





export default function Page404() {
  return (
    <div className="center-of-screen">
      <h1>Page Not Found</h1>
    </div>
  );
}