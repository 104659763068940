


import jaJP from 'antd/lib/locale/ja_JP';
import enUS from 'antd/lib/locale/en_US';
import deDE from 'antd/lib/locale/de_DE';
import frFR from 'antd/lib/locale/fr_FR';
import esES from 'antd/lib/locale/es_ES';
import { Locale } from 'antd/lib/locale';


const BROWSER_LANG = get_browser_lang()

const CURRENT_LANG = {
    "en": enUS,
    "de": deDE,
    "fr": frFR,
    "ja": jaJP,
    "es": esES,
}

export function get_curent(lang): Locale {
    const clang = CURRENT_LANG[lang]
    if (clang) {
        return clang
    }

    const blang = CURRENT_LANG[BROWSER_LANG]

    if(blang){
        return blang
    }

    return enUS
}

export function get_browser_lang() {
    const lang = (navigator.language || (navigator as any).userLanguage) || "en";

    if(lang?.length === 2){
        return lang.toLowerCase()
    }

    let clang = lang.split("-")

    if(clang?.length === 2){
        return clang[0].toLowerCase()
    }
    return "en"    
}


