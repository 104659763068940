
import React, { useEffect } from "react";
import konsole from "../../konsole";


export function useOutsideAlerter(ref, callback) {

  useEffect(() => {
    function handleClick(event) {
      if ((ref.current && !ref.current.contains(event.target))) {
        callback(event);
      }
    }

    function handlePointerUp(event) {
      handleClick(event);
    }
    document.addEventListener("click", handlePointerUp);
    return () => {
      document.removeEventListener("click", handlePointerUp);
    };
  }, [ref]);
}


export function useBlockOutsideEvents(ref, callback) {
  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        event.stopPropagation()
        event.preventDefault()
        callback(event)
      }
    }

    function handlePointerUp(event) {
      handleClick(event)
    }

    document.addEventListener("pointerup", handlePointerUp);
    return () => {
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [ref]);
}