const theme = {
  token: {
    wireframe: false,
    colorPrimary: "#3e4e82",
    colorPrimaryBg: "#212121",
    colorSuccess: "#56b129",
    colorWarning: "#b58017",
    colorError: "#b91119",
    colorInfo: "#2063c1",
    fontSize: 14,
    sizeStep: 4,
    sizeUnit: 4,
    marginXXS: 1,
    paddingXXS: 2,
    borderRadius: 6,
    fontFamily: "Clash Grotesk, sans-serif",
    colorBgMask: "rgba(0, 0, 0, 0.05)",
    bodyBg: "#212121",
    siderBg: "#212121",
  },
  components: {
    Drawer: {
      colorBgMask: "rgba(0, 0, 0, 0.07)",
    },
    Table: {
      headerColor: "rgb(45, 46, 118)",
      rowHoverBg: "rgb(250, 250, 250)",
      rowSelectedBg: "rgb(237, 243, 249)",
      rowSelectedHoverBg: "rgb(230, 238, 245)",
    },
    Modal: {
      fontWeightStrong: 500,
    },
  },
};

export default theme;
