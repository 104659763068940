import i18n from "i18next";
import urljoin from "url-join";
import { API_VERSION, BACKEND_API } from "../Params";
import { useEffect, useState } from "react";
import { Button, Col, notification, Row, Space } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";

import { RiErrorWarningFill, RiInformationFill } from "react-icons/ri";
import { BsFillCheckCircleFill, BsFillInfoCircleFill } from "react-icons/bs";
import { IoWarning, IoWarningOutline } from "react-icons/io5";
import { isMobile } from "react-device-detect";


export const customizeRenderEmpty = () => (
  <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
    {/* <>{i18n.t("folder_is_empty")}</> */}
    <>Folder is empty</>
  </span>
);

export interface WindowRect {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
}

export function getRect(el): WindowRect {
  if (!el) {
    return {} as WindowRect;
  }
  const rect = el.getBoundingClientRect();
  return {
    id: el.id,
    left: rect.left,
    top: rect.top,
    width: rect.width,
    height: rect.height,
  };
}

export function makeResizable() {
  // DOM utils:
  const el = (sel, par = document) => document.querySelector(sel);
  const els = (sel, par = document) => document.querySelectorAll(sel);
  const elNew = (tag, prop = {}) =>
    Object.assign(document.createElement(tag), prop);

  // Resizable
  let isResizing = false;
  const resizableGrid = (elParent, idx) => {
    const isVert = elParent.classList.contains("panes-v");
    const elsPanes = elParent.querySelectorAll(":scope > .pane");

    let fr = [...elsPanes].map(() => 1 / elsPanes.length);
    let elPaneCurr: any = null;
    let paneIndex: number = -1;
    let frStart: number = 0;
    let frNext: number = 0;

    const frToCSS = () => {
      elParent.style[isVert ? "grid-template-rows" : "grid-template-columns"] =
        fr.join("fr ") + "fr";
    };

    const pointerDown = (evt) => {
      if (isResizing || !evt.target.closest(".gutter")) return;
      isResizing = true;
      elPaneCurr = evt.currentTarget;
      fr = [...elsPanes].map((elPane) =>
        isVert
          ? elPane.clientHeight / elParent.clientHeight
          : elPane.clientWidth / elParent.clientWidth
      );
      paneIndex = [...elsPanes].indexOf(elPaneCurr);
      frStart = fr[paneIndex];
      frNext = fr[paneIndex + 1];
      document.addEventListener("pointermove", pointerMove);
      document.addEventListener("pointerup", pointerUp);
    };

    const pointerMove = (evt) => {
      evt.preventDefault();
      const paneBCR = elPaneCurr.getBoundingClientRect();
      const parentSize = isVert ? elParent.clientHeight : elParent.clientWidth;
      const pointer = {
        x: Math.max(
          0,
          Math.min(evt.clientX - paneBCR.left, elParent.clientWidth)
        ),
        y: Math.max(
          0,
          Math.min(evt.clientY - paneBCR.top, elParent.clientHeight)
        ),
      };
      const frRel = pointer[isVert ? "y" : "x"] / parentSize;
      const frDiff = frStart - frRel;
      fr[paneIndex] = Math.max(0.05, frRel);
      fr[paneIndex + 1] = Math.max(0.05, frNext + frDiff);
      frToCSS();
    };

    const pointerUp = (evt) => {
      document.removeEventListener("pointermove", pointerMove);
      document.removeEventListener("pointerup", pointerUp);
      isResizing = false;
    };

    [...elsPanes].slice(0, -1).forEach((elPane, i) => {
      elPane.append(
        elNew("div", {
          className: "gutter",
        })
      );
      elPane.addEventListener("pointerdown", pointerDown);
    });
    frToCSS();
  };

  els(".panes").forEach(resizableGrid);
}


export const PASSWORD_PATTERN_CHECK =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[#!@$%^&*()_+{}\[\];:<>?,./-]).{10,}$/;

  
export function buildResizableDiv() {

  const el = (sel, par = document) => par.querySelector(sel);
  const els = (sel, par = document) => par.querySelectorAll(sel);
  const elNew = (tag, prop = {}) =>
    Object.assign(document.createElement(tag), prop);

  const elHTML = el(`[data-lang="html"]`);
  const elCSS = el(`[data-lang="css"]`);
  const elJS = el(`[data-lang="js"]`);
  const elPreview = el("#preview");
  const elAutorun = el("#autorun");
  const elRun = el("#run");
  let hljs: any;
  let elPaneCurr: any;
  let totLines = 0;

  const updateLineNumbers = (elLines, elTextarea) => {
    if (!elLines) return;
    totLines = elTextarea.value.split(/\n/).length;
    elLines.innerHTML = "<span></span>".repeat(totLines);
  };

  const tabToSpaces = (evt) => {
    if (evt.key !== "Tab") return;
    const spaces = " ".repeat(4);
    evt.preventDefault(); // this will prevent us from tabbing out of the editor
    document.execCommand("insertHTML", false, spaces);
  };

  const updateTextareaSize = (elTextarea) => {
    requestAnimationFrame(() => {
      elTextarea.style.height = 0;
      elTextarea.style.height = `${elTextarea.scrollHeight}px`;
      elTextarea.style.width = 0;
      elTextarea.style.width = `${elTextarea.scrollWidth}px`;
    });
  };

  const hilite = (elCode, value) => {
    elCode.textContent = value;
    delete elCode.dataset.highlighted;
    hljs.highlightElement(elCode);
  };

  let previewTimeout;
  const preview = (isForce) => {
    if (!isForce && !elAutorun.checked) return;
    clearTimeout(previewTimeout);
    previewTimeout = setTimeout(
      () => {
        const html =
          `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>XODE document</title>
            <style>${elCSS.value}</style>
        </head>
        <body>
          ${elHTML.value}
          <scr` +
          `ipt>${elJS.value}</scr` +
          `ipt>
        </body>
        </html>`;
        elPreview.srcdoc = html;
      },
      isForce ? 0 : 260
    );
  };

  const makeEditor = (elEditor) => {
    const elTextarea = el(".editor-textarea", elEditor);
    const elCode = el(".editor-highlight > code", elEditor);
    const elLines = el(".editor-lines", elEditor);
    const elArea = el(".editor-area", elEditor);

    let elPaneCurr: any

    elTextarea.addEventListener("keydown", (evt) => {
      tabToSpaces(evt);
    });

    elTextarea.addEventListener("input", () => {
      hilite(elCode, elTextarea.value);
      updateLineNumbers(elLines, elTextarea);
      updateTextareaSize(elTextarea);
      preview(false);
    });

    // Update line numbers on window resize
    document.addEventListener(
      "resize",
      () => {
        updateTextareaSize(elTextarea);
      },
      true
    );

    // When tetarea is too small and if we click on -area move caret to end of textarea
    elArea.addEventListener("click", (evt) => {
      if (evt.target !== evt.currentTarget) return;
      const len = elTextarea.value.length;
      elTextarea.setSelectionRange(len, len);
      elTextarea.focus();
    });

    hilite(elCode, elTextarea.value);
    updateLineNumbers(elLines, elTextarea);
    updateTextareaSize(elTextarea);
  };

  // initialize editor
  els(".editor").forEach(makeEditor);
  preview(false);
  elRun.addEventListener("click", () => preview(true));

  /**
   * Resizable grid
   */

  let isResizing = false;
  const resizableGrid = (elParent, idx) => {
    const isVert = elParent.classList.contains("panes-v");
    const elsPanes = elParent.querySelectorAll(":scope > .pane");

    let fr = [...elsPanes].map((elPane) => {
      return parseFloat(elPane.dataset.fr || 1 / elsPanes.length);
    });
    let elPaneCurr: any = null;
    let paneIndex = -1;
    let frStart = 0;
    let frNext = 0;

    const frToCSS = () => {
      elParent.style[isVert ? "grid-template-rows" : "grid-template-columns"] =
        fr.join("fr ") + "fr";
    };

    const pointerDown = (evt) => {
      if (isResizing || !evt.target.closest(".gutter")) return;
      isResizing = true;
      elParent.classList.add("is-resizing");
      elPaneCurr = evt.currentTarget.previousElementSibling;
      fr = [...elsPanes].map((elPane) =>
        isVert
          ? elPane.clientHeight / elParent.clientHeight
          : elPane.clientWidth / elParent.clientWidth
      );
      paneIndex = [...elsPanes].indexOf(elPaneCurr);
      frStart = fr[paneIndex];
      frNext = fr[paneIndex + 1];
      document.addEventListener("pointermove", pointerMove);
      document.addEventListener("pointerup", pointerUp);
    };

    const pointerMove = (evt) => {
      evt.preventDefault();
      const paneBCR = elPaneCurr.getBoundingClientRect();
      const parentSize = isVert ? elParent.clientHeight : elParent.clientWidth;
      const pointer = {
        x: Math.max(
          0,
          Math.min(evt.clientX - paneBCR.left, elParent.clientWidth)
        ),
        y: Math.max(
          0,
          Math.min(evt.clientY - paneBCR.top, elParent.clientHeight)
        ),
      };
      const frRel = pointer[isVert ? "y" : "x"] / parentSize;
      const frDiff = frStart - frRel;
      fr[paneIndex] = Math.max(0.05, frRel);
      fr[paneIndex + 1] = Math.max(0.05, frNext + frDiff);
      frToCSS();
      elPaneCurr.dispatchEvent(new Event("resize"));
    };

    const pointerUp = (evt) => {
      document.removeEventListener("pointermove", pointerMove);
      document.removeEventListener("pointerup", pointerUp);
      isResizing = false;
      elParent.classList.remove("is-resizing");
    };

    [...elsPanes].slice(1).forEach((elPane, i) => {
      elPane.append(elNew("span", { className: "gutter" }));
      elPane.addEventListener("pointerdown", pointerDown);
    });

    frToCSS();
    window.dispatchEvent(new Event("resize"));
  };

  // Initialize grid!
  els(".panes").forEach(resizableGrid);
}



export function formatBytes(bytes, decimals = 2, add_unit = true) {
  if (!bytes) {
    return "0 Bytes";
  }
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const unit = add_unit ? sizes[i] : "";
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${unit}`;
}



export async function sleep(ms) {
  if (!ms) {
    return;
  }

  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function sleepUntil(condition: () => boolean, ms = 1000) {
  if (!ms) {
    ms = 1000;
  }

  while (!condition()) {
    await sleep(ms);
  }
}


export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};


const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
  window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};



export const config_urlencode = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const config_json = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};


export function prepare_query(q = {}, prepare_query_string = false) {
  const new_q = {};
  const q_str: any = [];

  if (prepare_query_string) {
    for (let k in q) {
      if (q[k] === undefined || q[k] === null) {
        continue;
      }
      q_str.push(encodeURIComponent(k) + "=" + encodeURIComponent(q[k]));
    }

    const resp = q_str.join("&");
    if (resp) {
      return "?" + resp;
    }
    return "";
  }

  for (const k in q) {
    if (q[k] === undefined || q[k] === null) {
      continue;
    }
    new_q[k] = q[k];
  }

  return new_q;
}

export function prepare_auth_url(method_name: string, q_str?: string) {
  if (q_str) {
    return urljoin(BACKEND_API, "/auth", method_name, q_str);
  }
  return urljoin(BACKEND_API, "/auth", method_name);
}

export function prepare_url(method_name: string, q_str?: string) {
  if (q_str) {
    return urljoin(BACKEND_API, API_VERSION, method_name, q_str);
  }
  return urljoin(BACKEND_API, API_VERSION, method_name);
}


export function truncateText(text, length = 12): string {
  if (!text) {
    return "";
  }
  if (text.length <= length) {
    return text;
  }
  return text.slice(0, length) + "...";
}




export async function copyToClipboard(text: string, successCallback?: () => void, failedCallback?: () => void) {
  try {
    const result = await (navigator as any).permissions.query({
      name: "clipboard-write" as PermissionName
    });

    if (result.state === "granted" || result.state === "prompt") {
      await navigator.clipboard.writeText(text);
      if (successCallback) {
        successCallback();
      }
      return;
    }

    if (result.state === "denied") {
      if (failedCallback) {
        failedCallback();
      }
    }
  } catch (error) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        if (successCallback) {
          successCallback();
        }
      } catch (err) {
        if (failedCallback) {
          failedCallback();
        }
      }
    } else {
      if (failedCallback) {
        failedCallback();
      }
    }
  }
}


export type NotificationType = "success" | "info" | "warning" | "error";

export interface showNotificationProps {
  message?: string;
  description?: any;
  className?: string;
  style?: React.CSSProperties | undefined;
  placement?: NotificationPlacement | undefined;
  icon?: React.ReactNode | undefined;
  type?: NotificationType | undefined;
  onClick?: any;
  onClose?: any;
  duration?: number | undefined;
  okBtnText?: String | undefined;
  closeBtnText?: String | undefined;
  onOk?: Function | undefined;
}

notification.config({
  placement: "bottomRight",
  rtl: false,
});

export function showNotification(props: showNotificationProps) {
  let icon;

  if (props.type === "success") {
    icon = (
      <BsFillCheckCircleFill
        style={{ color: "#52c41a", fontSize: "32px", marginLeft: "-6px" }}
      />
    );
  } else if (props.type === "warning") {
    icon = (
      <IoWarningOutline
        style={{ color: "#faad14", fontSize: "32px", marginLeft: "-6px" }}
      />
    );
  } else if (props.type === "error") {
    icon = (
      <RiErrorWarningFill
        style={{ color: "#f5222d", fontSize: "32px", marginLeft: "-6px" }}
      />
    );
  } else {
    icon = (
      <RiInformationFill
        style={{ color: "#063970", fontSize: "32px", marginLeft: "-6px" }}
      />
    );
  }
  function onOk() {
    if (props.onOk) {
      props.onOk();
      notification.destroy();
    }
  }
  const btn = props.onOk ? (
    <Space>
      <Button className="no-text-select" type="primary" onClick={onOk}>
        {props.okBtnText}
      </Button>
      <Button className="no-text-select" onClick={() => notification.destroy()}>
        {props.closeBtnText}
      </Button>
    </Space>
  ) : null;
  notification.open({
    message: props.message || "",
    description: props.description || "",
    className: props.className || "",
    style: props.style || { width: "400px" },
    placement: isMobile ? "bottom" : "bottomRight",
    icon: props.icon || icon,
    type: props.type || "info",
    onClick: props.onClick,
    onClose: props.onClose,
    duration: props.duration === undefined ? 5 : props.duration,
    btn: btn,
  });
}