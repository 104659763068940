import axios from "axios";
import {
  ACCESS_TOKEN_KEY,
  BLAZE_PUBLIC_KEY_URL,
  DS_CACHE_KEY,
  DS_CACHE_KEY_DATE_FORMAT,
  DS_CACHE_KEY_LANG,
  ENCRYPTION_CODE_KEY,
  PUBLIC_KEY_URL,
  REFRESH_TOKEN_KEY
} from "../Params";
import i18n from "../_Local";
import konsole from "../konsole";
import { sleep } from "../utils";
import { getKeyObject } from "./public_xp_func";
import { checkJWT_jwtlib } from "./utils";
import { Subject } from "rxjs";
export let ACCESS_TOKEN: string = "";
export let REFRESH_TOKEN: string = "";
export let PUBLIC_KEY: string = "";
export let PUBLIC_XP_KEY: any;
export const AUTH_STATUS_KEY = "ds_auth_status";
export let TOKEN_DATA: TokenCacheProps = {};
export let IN_MEMORY_TOKEN_DATA: TokenCacheProps = {};

let USE_LOCALSTORGAE = true;
let ENCRYPTION_CODE: string | null = "";
let IN_MEMORY_ENCRYPTION_CODE: string | null = "";

export const USER_TYPE_SATBNDARD = "standard";
export const USER_TYPE_GUEST = "guest";
export type UserTypeProps = "standard" | "guest";

let CNAME_PATH_ALLOWED = false;

export function set_CNAME_PATH_ALLOWED(status: boolean) {
  CNAME_PATH_ALLOWED = status;
  return CNAME_PATH_ALLOWED;
}

export function get_CNAME_PATH_ALLOWED() {
  return CNAME_PATH_ALLOWED;
}

export function set_USE_LOCALSTORGAE(status: boolean) {
  USE_LOCALSTORGAE = status;
  return USE_LOCALSTORGAE;
}

export function get_USE_LOCALSTORGAE() {
  return USE_LOCALSTORGAE;
}

export function set_TOKEN_DATA(data: TokenCacheProps) {
  TOKEN_DATA = data;
  return TOKEN_DATA;
}

export function get_TOKEN_DATA() {
  return TOKEN_DATA;
}

let ACCESS_TOKEN_CACHE = "";
let REFRESH_TOKEN_CACHE = "";


export function getAuthStatusFromPayload(user_type: UserTypeProps) {
  return user_type === TOKEN_DATA?.payload?.data?.user_type;
}

export interface DriveTokenProps {
  access_token?: string;
  refresh_token?: string;
}
export interface PayloadDataProps {
  _id?: string;
  username?: string;
  email?: string;
  currency?: string;
  firstname?: string;
  lastname?: string;
  profile_url?: string;
  scopes?: string;
  stripe_id?: string;
  updated_at?: string;
  user_type: string;
  language?: string;
  locale?: string;
  active?: boolean;
  is_mfa_active?: boolean;
  created_at?: string;
  created_by?: string;
}

export interface PayloadProps {
  aud: string;
  csrf: string;
  exp: number;
  iat: number;
  ip: string;
  iss: string;
  jti: string;
  sub: string;
  type: string;
  data?: PayloadDataProps;
}

export interface ClipboardDataProps {
  [key: string]: any;
}

export interface CustomAccessdDataProps {
  [key: string]: any;
}

export interface SelectedSendFileTabProps {
  [key: string]: "my_workspace" | "emails" | "link";
}
export interface SelectedSendDaysProps {
  [key: string]: number;
}

export interface ActiveUserProps {
  email: string;
  firstname: string;
  lastname: string;
}
export interface TokenCacheProps {
  access_token?: string;
  refresh_token?: string;
  payload?: PayloadProps;
  firstname?: string;
  lastname?: string;
  activeUser?: ActiveUserProps;
  selectedCompany?: {};
  selectedCustomer?: {};
  selectedOrganization?: {};
  selectedProject?: {};
  selectedRoom?: {};
  selectedModel?: {};
  selectedStore?: {};
  selectedStatus?: {};
  selectedSectionGroup?: {};
  selectedDepartment?: {};
  selectedJICFS?: {};
  seletedUnit?: {};
  selectedDai?: {};
  selectedTana?: {};
  siderCollapsed?: boolean;
  language?: string;
  invitationCount?: number;
  hideSideMenu?: boolean;
  lightDarkMode?: "light" | "dark" | "auto";
  loalization?: string;
  googleDriveAccessToken?: string;
  googleDriveRefreshToken?: string;
  oneDriveAccessToken?: string;
  oneDriveRefreshToken?: string;
  dateFormat?: string;
  clipboardData?: ClipboardDataProps;
  customAccessData?: CustomAccessdDataProps;
  sendingMode?: "internal" | "external" | "link";
  selectedSendFileTab?: SelectedSendFileTabProps;
  selectedSendDays?: SelectedSendDaysProps;
  encryption_code?: string;
  sendTab?: "my_workspace" | "emails" | "link";
  fileAndPortalsTab?: "sent" | "received" | "portals";
  dataRetentionDays?: number;
  selectedHistoryTabPosition?: "sent" | "received" | "portals";
  ms_access_token?: string;
}

export interface TokenCachePropsKeys {
  key:
    | "access_token"
    | "refresh_token"
    | "payload"
    | "firstname"
    | "lastname"
    | "activeUser"
    | "language"
    | "selectedProject"
    | "selectedOrganization"
    | "googleDriveAccessToken"
    | "googleDriveRefreshToken"
    | "oneDriveAccessToken"
    | "oneDriveRefreshToken"
    | "loalization"
    | "siderCollapsed"
    | "hideSideMenu"
    | "clipboardData"
    | "customAccessData"
    | "sendingMode"
    | "selectedSendFileTab"
    | "selectedSendDays"
    | "b_ec" // encryption_code
    | "sendTab"
    | "fileAndPortalsTab"
    | "dataRetentionDays"
    | "selectedHistoryTabPosition"
    | "ms_access_token";
}

let IS_PROCESSING = false;

const LOCAL_SORAGE_SAVE_SUBS = new Subject<string>();

LOCAL_SORAGE_SAVE_SUBS.subscribe(async (data) => {
  try {
    while (IS_PROCESSING) {
      await sleep(100);
    }

    IS_PROCESSING = true;

    if (get_USE_LOCALSTORGAE()) {
      window.localStorage.setItem(DS_CACHE_KEY, data);
    }
  } catch (e) {
    konsole.log(e);
  } finally {
  }
  IS_PROCESSING = false;
});



export async function validatedToken({ access_token }) {

  console.log("validatedToken:::: access_token:::: ", access_token);
  try {
    const payload = await checkJWT_jwtlib(access_token);
    return payload;
  } catch (e) {
    konsole.log(`Access token not valid: ${e}`, access_token);
  }
  return null;
}

export async function loadTokenCache() {
  let token_data: TokenCacheProps = getTokenCache();
  let access_token = read_AccessToken();
  let payload = await validatedToken({ access_token });
  token_data.payload = payload;
  saveTokenCache(token_data);
  return token_data;
}


export async function loadTokenCacheSecrued() {
  let token_data: TokenCacheProps = await getTokenCacheAsync();

  let access_token = read_AccessToken();
  let payload = await validatedToken({ access_token: access_token });
  if (!payload) {
    return {};
  }
  token_data.payload = payload;
  saveTokenCache(token_data);
  return token_data;
}

export async function createTokenCache({ access_token, refresh_token }) {
  let new_token_data: TokenCacheProps = {};
  const payload = await validatedToken({ access_token });
  new_token_data.activeUser = {
    email: payload.data.username,
    firstname: payload.data.firstname,
    lastname: payload.data.lastname,
  };
  if (!access_token || access_token === "undefined" || access_token === "") {
    konsole.log(
      "createTokenCache::: access_token is empty ",
      access_token,
      refresh_token
    );
    throw new Error("Access token is empty");
  }

  if (!refresh_token || refresh_token === "undefined" || refresh_token === "") {
    konsole.log(
      "createTokenCache::: refresh_token is empty ",
      access_token,
      refresh_token
    );
    throw new Error("Refresh token is empty");
  }

  write_AccessToken(access_token);
  write_RefreshToken(refresh_token);
  new_token_data.payload = payload;
  return saveTokenCache(new_token_data);
}

export function write_AccessToken(access_token, reset = false) {
  if (reset) {
    access_token = "";
  } else {
    if (!access_token || access_token === "undefined") {
      konsole.log("write_AccessToken::: access_token is empty ", access_token);
      throw new Error("Access token is empty:: ");
    }
  }

  ACCESS_TOKEN_CACHE = access_token;
  if (USE_LOCALSTORGAE) {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  }
}

export function read_AccessToken() {
  if (ACCESS_TOKEN_CACHE) {
    return ACCESS_TOKEN_CACHE;
  }
  if (USE_LOCALSTORGAE) {
    ACCESS_TOKEN_CACHE = window.localStorage.getItem(ACCESS_TOKEN_KEY) ?? "";
  }
  return ACCESS_TOKEN_CACHE;
}

export function write_RefreshToken(refresh_token, reset = false) {
  if (reset) {
    refresh_token = "";
  } else {
    if (refresh_token === "undefined" || refresh_token === undefined) {
      konsole.log(
        "write_RefreshToken::: refresh_token is empty ",
        refresh_token
      );
      throw new Error("Refresh token is empty:: ");
    }
  }

  REFRESH_TOKEN_CACHE = refresh_token;
  if (USE_LOCALSTORGAE) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  }
}

export function read_RefreshToken() {
  if (REFRESH_TOKEN_CACHE) {
    return REFRESH_TOKEN_CACHE;
  }
  if (USE_LOCALSTORGAE) {
    REFRESH_TOKEN_CACHE = window.localStorage.getItem(REFRESH_TOKEN_KEY) ?? "";
  }
  return REFRESH_TOKEN_CACHE;
}

export function saveTokenCache(token_data: TokenCacheProps) {
  try {
    TOKEN_DATA = token_data;
    const token_data_str = JSON.stringify(token_data);
    if (USE_LOCALSTORGAE) {
      window.localStorage.setItem(DS_CACHE_KEY, token_data_str);
    }
    return token_data;
  } catch (e) {
    konsole.log(`could not same token_data to local storage: ${e}`);
  }
  TOKEN_DATA = {};
  return TOKEN_DATA;
}

export function setClipboardData(data: ClipboardDataProps) {
  const token_data: any = getTokenCache();
  const clipboardData: any = token_data?.clipboardData;

  if (!clipboardData) {
    token_data.clipboardData = {};
  }
  token_data.clipboardData = { ...token_data.clipboardData, ...data };
  saveTokenCache(token_data);
}

export function resetClipboardData() {
  const token_data: any = getTokenCache();
  const clipboardData: any = token_data?.clipboardData || {};
  if (Object.keys(clipboardData.length)) {
    token_data.clipboardData = {};
  }
  saveTokenCache(token_data);
}

export function getClipboardDataItem(key: string) {
  if (!key) {
    return null;
  }
  let clipboard_data = getTokenCache().clipboardData || {};
  return clipboard_data[key];
}

export function removeClipboardDataItem(key: string) {
  const token_data = getTokenCache();
  let clipboardData = token_data.clipboardData || {};
  delete clipboardData[key];

  saveTokenCache(token_data);
}

export async function setEncryptionCode(value) {
  ENCRYPTION_CODE = value;
  if (USE_LOCALSTORGAE) {
    setTokenItem(ENCRYPTION_CODE_KEY, value);
  }
}

export async function getEncryptionCode() {
  if (USE_LOCALSTORGAE) {
    ENCRYPTION_CODE =
      getTokenItem(ENCRYPTION_CODE_KEY) ||
      window.localStorage.getItem(ENCRYPTION_CODE_KEY);
  }
  return ENCRYPTION_CODE;
}

export function getTokenCache(): TokenCacheProps {
  if (Object.keys(TOKEN_DATA).length) {
    return TOKEN_DATA;
  }

  if (USE_LOCALSTORGAE === false) {
    return TOKEN_DATA;
  }

  try {
    TOKEN_DATA = JSON.parse(window.localStorage.getItem(DS_CACHE_KEY) || "{}");
    return TOKEN_DATA;
  } catch (e) {
    konsole.log(`Could not get token cache ${e}`);
    throw e;
  }
}

export async function getTokenCacheAsync(): Promise<TokenCacheProps> {
  if (Object.keys(TOKEN_DATA).length) {
    return TOKEN_DATA;
  }

  if (USE_LOCALSTORGAE === false) {
    return TOKEN_DATA;
  }

  try {
    let data: string = window.localStorage.getItem(DS_CACHE_KEY) || "{}";
    TOKEN_DATA = JSON.parse(data);
    return TOKEN_DATA;
  } catch (e) {
    konsole.log(`Could not get token cache ${e}`);
    throw e;
  }
}

export type DateFormatProps =
  | "DD/MM/YYYY"
  | "MM/DD/YYYY"
  | "YYYY/MM/DD"
  | "DD.MM.YYYY"
  | "MM.DD.YYYY"
  | "YYYY.MM.DD"
  | "DD-MM-YYYY"
  | "MM-DD-YYYY"
  | "YYYY-MM-DD";

export type DateTimeFormatProps =
  | "DD/MM/YYYY HH:MM"
  | "MM/DD/YYYY HH:MM"
  | "YYYY/MM/DD HH:MM"
  | "DD.MM.YYYY HH:MM"
  | "MM.DD.YYYY HH:MM"
  | "YYYY.MM.DD HH:MM"
  | "DD-MM-YYYY HH:MM"
  | "MM-DD-YYYY HH:MM"
  | "YYYY-MM-DD HH:MM";

let ACTIVE_LANGUAGE = "";
let ACTIVE_LOCALE_DATE_FORMAT: DateFormatProps;

export function get_ACTIVE_LOCALE_REGION(): DateFormatProps {
  return ACTIVE_LOCALE_DATE_FORMAT;
}

export function set_ACTIVE_LOCALE_REGION(date_format: DateFormatProps) {
  ACTIVE_LOCALE_DATE_FORMAT = date_format;
}

export function setActiveLanguageCache(lang) {
  const key = DS_CACHE_KEY_LANG;
  ACTIVE_LANGUAGE = lang;
  if (USE_LOCALSTORGAE) {
    window.localStorage.setItem(key, JSON.stringify(lang || ""));
  }
}

export function getActiveLanguageCache() {
  if (ACTIVE_LANGUAGE) {
    return ACTIVE_LANGUAGE;
  }
  const key = DS_CACHE_KEY_LANG;
  ACTIVE_LANGUAGE = JSON.parse(window.localStorage.getItem(key) || '""');
  return ACTIVE_LANGUAGE;
}

export function loadActiveLanguageCache() {
  let lang = getActiveLanguageCache();
  if (!lang) {
    lang = i18n.language;
    setActiveLanguageCache(lang);
    return lang;
  }
  return lang;
}

export function setActiveLocalDateFormatCache(date_format) {
  const key = DS_CACHE_KEY_DATE_FORMAT;
  ACTIVE_LOCALE_DATE_FORMAT = date_format;
  if (USE_LOCALSTORGAE) {
    window.localStorage.setItem(key, date_format || "");
  }
}


export function getActiveLocalDateFormatCache() {
  if (ACTIVE_LOCALE_DATE_FORMAT) {
    return get_ACTIVE_LOCALE_REGION();
  }
  const key = DS_CACHE_KEY_DATE_FORMAT;
  ACTIVE_LOCALE_DATE_FORMAT = (window.localStorage.getItem(key) ||
    "") as DateFormatProps;
  return ACTIVE_LOCALE_DATE_FORMAT;
}

export function loadActiveLocalDateFormatCache() {
  let date_format = getActiveLocalDateFormatCache();
  if (!date_format) {
    date_format = "DD/MM/YYYY";
    setActiveLocalDateFormatCache(date_format);
    return date_format;
  }
  return date_format;
}

export function updateTokenCache(token_data: TokenCacheProps) {
  for (let k in token_data) {
    if (token_data[k] !== undefined) {
      TOKEN_DATA[k] = token_data[k];
    }
  }
  return saveTokenCache(TOKEN_DATA);
}

export function resetTokenCache() {
  window.localStorage.setItem("ms_access_token", "{}");
  write_AccessToken("", true); // reset access token
  write_RefreshToken("", true); // reset refresh token
  return saveTokenCache({});
}

export function isOldRefreshToken() {
  window.localStorage.setItem("ms_access_token", "{}");
  return saveTokenCache({});
}

export function getTokenItem(key) {
  const token_data = getTokenCache();
  return token_data[key];
}

export function setTokenItem(key, value) {
  TOKEN_DATA[key] = value;
  saveTokenCache(TOKEN_DATA);
}

export function isAuthenticated(user_type: UserTypeProps) {
  return getAuthStatusFromPayload(user_type);
}

export async function setAccessTokenCache(value, reset = false) {
  write_AccessToken(value, reset);
  const payload = await validatedToken({ access_token: value });
  const data = getTokenCache();
  data["payload"] = payload;
  saveTokenCache(data);
}

export async function setRefreshTokenCache(value, reset = false) {
  write_RefreshToken(value, reset);
}

export async function getAccessToken() {
  const token = read_AccessToken();
  return token;
}

export async function getRefreshToken() {
  const refresh_token = read_RefreshToken();
  return refresh_token;
}

export function getPayload() {
  return getTokenCache().payload;
}

export function getUserName() {
  const payload: any = getTokenCache().payload || {};
  if (payload.data) {
    return payload.data.username;
  }
  return "";
}

export function getIsMfhaActive() {
  const payload: any = getTokenCache().payload || {};
  if (payload?.data) {
    return payload?.data?.is_mfa_active;
  }
  return false;
}

export function getScopes() {
  return getPayloadValue("scopes") || "";
}

export function isUnitScope() {
  return getScopes() === "unit";
}

export function prepareActiveUserData({ username, email }) {
  return { username, email };
}

export function setUserName(username) {
  const payload: any = { ...(getTokenCache().payload || {}) };

  if (payload.data) {
    payload.data["username"] = username;
  }
  return "";
}

export function getUserId() {
  return getPayloadValue("_id");
}
export function getPayloadValue(key) {
  return (((getTokenCache().payload || {}) as any).data || {})[key];
}

export function setPayloadValue(key, value) {
  const token_data: any = (getTokenCache() || {}) as any;
  const payload: any = (token_data.payload || {}) as any;
  const new_data: PayloadProps = payload.data || {};
  new_data[key] = value;
  payload["data"] = new_data;
  token_data["payload"] = payload;
  saveTokenCache(token_data);
}

export function getEmail() {
  const payload: any = getTokenCache().payload || {};
  if (payload.data) {
    return payload.data.email;
  }
  return "";
}

export function getSelectedCompanyFromCache() {
  return getTokenCache().selectedCompany || {};
}

export async function loadPublicKey() {
  try {
    const result = await axios.get(PUBLIC_KEY_URL);
    if (result && result.data) {
      PUBLIC_KEY = result.data;
    } else {
      PUBLIC_KEY = "";
    }

    PUBLIC_XP_KEY = getKeyObject(PUBLIC_KEY);
    await sleep(500);
    return PUBLIC_KEY;
  } catch (e) {
    konsole.log(e);
  }
  PUBLIC_KEY = "";
  return "";
}

export async function getBlazePublicKey() {
  try {
    const result = await axios.get(BLAZE_PUBLIC_KEY_URL);
    if (result && result?.data?.data) {
      PUBLIC_KEY = result?.data?.data;
    } else {
      PUBLIC_KEY = "";
    }
    return PUBLIC_KEY;
  } catch (e) {
    konsole.log(e);
  }
  PUBLIC_KEY = "";
  return "";
}

export function setGoogleDriveAccessToken(access_token, refresh_token) {
  TOKEN_DATA.googleDriveAccessToken = access_token || "";
  TOKEN_DATA.googleDriveRefreshToken = refresh_token || "";
  saveTokenCache(TOKEN_DATA);
}
export function getGoogleDriveAccessToken() {
  return TOKEN_DATA.googleDriveAccessToken;
}

export function getGoogleDriveRefreshToken() {
  return TOKEN_DATA.googleDriveRefreshToken;
}

export function setOneDriveAccessToken(access_token, refresh_token) {
  TOKEN_DATA.oneDriveAccessToken = access_token || "";
  TOKEN_DATA.oneDriveRefreshToken = refresh_token || "";
  saveTokenCache(TOKEN_DATA);
}
export function getOneDriveAccessToken() {
  return TOKEN_DATA.oneDriveAccessToken;
}

export function getOneDriveRefreshToken() {
  return TOKEN_DATA.oneDriveRefreshToken;
}

export function getTokenCacheInMemory(): TokenCacheProps {
  return IN_MEMORY_TOKEN_DATA;
}

export function getPayloadInMemory() {
  return getTokenCacheInMemory().payload;
}

export function getUserNameInMemory() {
  const payload: any = getTokenCacheInMemory().payload || {};
  if (payload.data) {
    return payload.data.username;
  }
  return "";
}

export async function setEncryptionCodeInMemory(value) {
  IN_MEMORY_ENCRYPTION_CODE = value;
}

export async function getEncryptionCodeInMemory() {
  return IN_MEMORY_ENCRYPTION_CODE;
}

const REFRESH_INTERVAL = Math.floor(10 * 60 * 1000); // 10 minutes
