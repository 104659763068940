import { Layout } from "antd";
import { theme } from "../../Store/theme";
import { Content } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import NavigationSideMenu from "../../components/NavigationSideMenu";
import TopBar from "../../components/TopBar";
import ProtectedRoute from "../__AppRouter__/ProtectedRoute";
import { useRecoilValue } from "recoil";
import { getWindowDimensions } from "../../utils";
import { useEffect, useState } from "react";



export default function MainSearchPage() {
  const _theme: any = useRecoilValue(theme);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // const bgColor = _theme === "light" ? "black" : "white"
  const bgColor = _theme === "light" ? "black" : "white"

  
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);




  return (
    <ProtectedRoute>
      <Layout style={{ height: windowDimensions?.height,  background: bgColor}}>
        <TopBar />
        <Layout>
          {/* <NavigationSideMenu />     */}
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </ProtectedRoute>
  );
}
