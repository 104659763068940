import { App as App0, ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";
import { ROUTES } from "./Pages/__AppRouter__/route_definition";
import { selctedThemeState } from "./__GlobalStates__";
import { customizeRenderEmpty } from "./utils";
import MySpinner from "./components/MySpinner";
import { useEffect, useState } from "react";
import { init_i18n } from "./_Local";
import { loadPublicKey } from "./Auth";
import { I18nextProvider } from "react-i18next";
import i18n from "./_Local";
import { get_curent } from "./__GlobalStates__/locale";
import { isAuthenticated } from "./Params";

export default function App() {
  const selectedTheme = useRecoilValue(selctedThemeState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    (async () => {
      try {
        init_i18n();
        await loadPublicKey();
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  

  if (isLoading) {
    return <MySpinner />;
  }

  return (
    <ConfigProvider
      theme={selectedTheme}
      renderEmpty={customizeRenderEmpty}
      locale={get_curent(i18n.language)}
    >
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={ROUTES} fallbackElement={<MySpinner />} />        
      </I18nextProvider>
    </ConfigProvider>
  );
}
