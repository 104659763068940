export type TranslationData = Array<{
  [id: string]: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    pt?: string;
    ch?: string;
    ja?: string;
    ko?: string;
    it?: string;
    pl?: string;
    ro?: string;
  };
}>;

// add italian, korian, chinese, portuguese translations in data
const data: TranslationData = [
  {
    hello: {
      en: "Hello",
      fr: "Bonjour",
      de: "Halo",
      ja: "日本語",
      es: "hola",
      it: "ciao",
      ko: "안녕하세요",
    },
    create_new_chat: {
      en: "Create New Chat",
      fr: "Créer un nouveau chat",
      de: "Neuen Chat erstellen",
      ja: "新しいチャットを作成",
      es: "Crear nuevo chat",
      it: "Crea una nuova chat",
      ko: "새 채팅 만들기",
    },
    name: {
      en: "Name",
      fr: "Nom",
      de: "Name",
      ja: "名前",
      es: "Nombre",
      it: "Nome",
      ko: "이름",
    },
    cancel: {
      en: "Cancel",
      fr: "Annuler",
      de: "Stornieren",
      ja: "キャンセル",
      es: "Cancelar",
      it: "Annulla",
      ko: "취소",
    },
    save: {
      en: "Save",
      fr: "Sauvegarder",
      de: "Sparen",
      ja: "セーブ",
      es: "Guardar",
      it: "Salvare",
      ko: "저장",
    },
    create: {
      en: "Create",
      fr: "Créer",
      de: "Erstellen",
      ja: "作成する",
      es: "Crear",
      it: "Creare",
      ko: "만들기",
    },
    create_chat: {
      en: "Create Chat",
      fr: "Créer un chat",
      de: "Chat erstellen",
      ja: "チャットを作成",
      es: "Crear chat",
      it: "Crea chat",
      ko: "채팅 만들기",
    },
    chat_name: {
      en: "Chat Name",
      fr: "Nom du chat",
      de: "Chatname",
      ja: "チャット名",
      es: "Nombre del chat",
      it: "Nome della chat",
      ko: "채팅 이름",
    },
    writing: {
      en: "Writing",
      fr: "Écriture",
      de: "Schreiben",
      ja: "執筆",
      es: "Escritura",
      it: "Scrittura",
      ko: "글쓰기",
    },
    generating: {
      en: "Generating",
      fr: "Génération",
      de: "Generierung",
      ja: "生成",
      es: "Generando",
      it: "Generazione",
      ko: "생성",
    },
    filename: {
      en: "Filename",
      fr: "Nom de fichier",
      de: "Dateiname",
      ja: "ファイル名",
      es: "Nombre de archivo",
      it: "Nome del file",
      ko: "파일 이름",
    },
    content_type: {
      en: "Content Type",
      fr: "Type de contenu",
      de: "Inhaltstyp",
      ja: "コンテンツタイプ",
      es: "Tipo de contenido",
      it: "Tipo di contenuto",
      ko: "콘텐츠 유형",
    },
    size: {
      en: "Size",
      fr: "Taille",
      de: "Größe",
      ja: "サイズ",
      es: "Talla",
      it: "Taglia",
      ko: "크기",
    },
    created_at: {
      en: "Created At",
      fr: "Créé à",
      de: "Erstellt am",
      ja: "で作成されました",
      es: "Creado en",
      it: "Creato a",
      ko: "에 만들어진",
    },
    delete: {
      en: "Delete",
      fr: "Effacer",
      de: "Löschen",
      ja: "削除",
      es: "Borrar",
      it: "Elimina",
      ko: "지우다",
    },
    chat_history: {
      en: "Chat History",
      fr: "Historique des chats",
      de: "Chatverlauf",
      ja: "チャット履歴",
      es: "Historial de chat",
      it: "Cronologia chat",
      ko: "채팅 기록",
    },
    knowledge_base: {
      en: "Knowledge Base",
      fr: "Base de connaissances",
      de: "Wissensbasis",
      ja: "ナレッジベース",
      es: "Base de conocimientos",
      it: "Base di conoscenza",
      ko: "지식 베이스",
    },
    chat_files: {
      en: "Chat Files",
      fr: "Fichiers de chat",
      de: "Chatdateien",
      ja: "チャットファイル",
      es: "Archivos de chat",
      it: "File di chat",
      ko: "채팅 파일",
    },
    add: {
      en: "Add",
      fr: "Ajouter",
      de: "Hinzufügen",
      ja: "追加",
      es: "Añadir",
      it: "Inserisci",
      ko: "추가",
    },
    remove: {
      en: "Remove",
      fr: "Retirer",
      de: "Entfernen",
      ja: "削除",
      es: "Eliminar",
      it: "Rimuovere",
      ko: "없애다",
    },
    upload: {
      en: "Upload",
      fr: "Télécharger",
      de: "Hochladen",
      ja: "アップロード",
      es: "Subir",
      it: "Caricare",
      ko: "업로드",
    },
    ready: {
      en: "Ready",
      fr: "Prêt",
      de: "Bereit",
      ja: "準備完了",
      es: "Listo",
      it: "Pronto",
      ko: "준비 완료",
    },
    not_ready: {
      en: "Not Ready",
      fr: "Pas prêt",
      de: "Nicht bereit",
      ja: "準備ができていません",
      es: "No listo",
      it: "Non pronto",
      ko: "준비 안 됨",
    },
    files_added_in_this_discussion: {
      en: "Files added in this discussion",
      fr: "Fichiers ajoutés dans cette discussion",
      de: "Dateien in dieser Diskussion hinzugefügt",
      ja: "このディスカッションに追加されたファイル",
      es: "Archivos agregados en esta discusión",
      it: "File aggiunti in questa discussione",
      ko: "이 토론에 추가된 파일"
    },
    no_file_is_selected_please_attach_files_to_chat: {
      en: "No file is selected. Please attach files to chat",
      fr: "Aucun fichier n'est sélectionné. Veuillez attacher des fichiers au chat",
      de: "Keine Datei ausgewählt. Bitte Dateien an den Chat anhängen",
      ja: "ファイルが選択されていません。 チャットにファイルを添付してください",
      es: "No se ha seleccionado ningún archivo. Adjunte archivos al chat",
      it: "Nessun file è selezionato. Allegare file alla chat",
      ko: "선택된 파일이 없습니다. 채팅에 파일을 첨부하십시오"
    },
    please_login : {
      en: "Please login",
      fr: "Veuillez vous connecter",
      de: "Bitte einloggen",
      ja: "ログインしてください",
      es: "Por favor inicie sesión",
      it: "Si prega di accedere",
      ko: "로그인 해주세요"
    },
    log_in: {
      en: "Log In",
      fr: "Se connecter",
      de: "Einloggen",
      ja: "ログイン",
      es: "Iniciar sesión",
      it: "Accesso",
      ko: "로그인"
    },
    forgot_password: {
      en: "Forgot Password ?",
      fr: "Mot de passe oublié ?",
      de: "Passwort vergessen ?",
      ja: "パスワードを忘れましたか？",
      es: "¿Olvidaste tu contraseña ?",
      it: "Password dimenticata ?",
      ko: "비밀번호를 잊으셨나요 ?"
    },
    nouma_ai: {
      en: "Nouma AI",
      fr: "Nouma AI",
      de: "Nouma AI",
      ja: "Nouma AI",
      es: "Nouma AI",
      it: "Nouma AI",
      ko: "Nouma AI"
    },
    nouma_tagline: {
      en: "LLM powered search and analysis",
      fr: "Recherche et analyse de documents alimentées par LLM",
      de: "LLM-basierte Dokumentsuche und -analyse",
      ja: "LLMパワードドキュメント検索と分析",
      es: "Búsqueda y análisis de documentos alimentados por LLM",
      it: "Ricerca e analisi di documenti alimentati da LLM",
      ko: "LLM 기반 문서 검색 및 분석"
    },
    sign_out: {
      en: "Sign Out",
      fr: "Déconnexion",
      de: "Ausloggen",
      ja: "サインアウト",
      es: "Desconectar",
      it: "Disconnettersi",
      ko: "로그아웃"
    },
    sign_up: {
      en: "Sign Up",
      fr: "S'inscrire",
      de: "Anmelden",
      ja: "サインアップ",
      es: "Regístrate",
      it: "Iscriviti",
      ko: "가입하기"
    },
    and_try_for_free: {
      en: "and try for free",
      fr: "et essayez gratuitement",
      de: "und kostenlos ausprobieren",
      ja: "無料でお試しください",
      es: "y pruébalo gratis",
      it: "e prova gratuitamente",
      ko: "무료로 시도하십시오"
    },
    your_account_created_successfully_check_email: {
      en: "Your account created successfully. Please check your email",
      fr: "Votre compte a été créé avec succès. Veuillez vérifier votre e-mail",
      de: "Ihr Konto wurde erfolgreich erstellt. Bitte überprüfen Sie Ihre E-Mail",
      ja: "アカウントが正常に作成されました。 メールを確認してください",
      es: "Su cuenta se creó correctamente. Por favor revise su correo electrónico",
      it: "Il tuo account è stato creato con successo. Controlla la tua email",
      ko: "계정이 성공적으로 생성되었습니다. 이메일을 확인하십시오"
    },
    workspace: {
      en: "Workspace",
      fr: "Espace de travail",
      de: "Arbeitsbereich",
      ja: "ワークスペース",
      es: "Espacio de trabajo",
      it: "Spazio di lavoro",
      ko: "작업 공간"
    },
    create_your_account: {
      en: "Create your account",
      fr: "Créez votre compte",
      de: "Erstellen Sie Ihr Konto",
      ja: "アカウントを作成する",
      es: "Crea tu cuenta",
      it: "Crea il tuo account",
      ko: "계정 만들기"
    },
    firstname: {
      en: "First Name",
      fr: "Prénom",
      de: "Vorname",
      ja: "名",
      es: "Nombre de pila",
      it: "Nome di battesimo",
      ko: "이름"
    },
    enter_first_name: {
      en: "Enter First Name",
      fr: "Entrez le prénom",
      de: "Geben Sie den Vornamen ein",
      ja: "名を入力してください",
      es: "Ingrese el nombre",
      it: "Inserisci il nome",
      ko: "이름을 입력하십시오"
    },
    lastname: {
      en: "Last Name",
      fr: "Nom de famille",
      de: "Nachname",
      ja: "苗字",
      es: "Apellido",
      it: "Cognome",
      ko: "성"
    },
    enter_last_name: {
      en: "Enter Last Name",
      fr: "Entrez le nom de famille",
      de: "Geben Sie den Nachnamen ein",
      ja: "苗字を入力してください",
      es: "Ingrese el apellido",
      it: "Inserisci il cognome",
      ko: "성을 입력하십시오"
    },
    enter_email: {
      en: "Enter Email",
      fr: "Entrez l'email",
      de: "E-Mail eingeben",
      ja: "メールアドレスを入力",
      es: "Introducir correo electrónico",
      it: "Inserisci l'email",
      ko: "이메일 입력"
    },
    enter_valid_email: {
      en: "Enter Valid Email",
      fr: "Entrez un e-mail valide",
      de: "Geben Sie eine gültige E-Mail-Adresse ein",
      ja: "有効なメールアドレスを入力してください",
      es: "Introduzca un correo electrónico válido",
      it: "Inserisci un'email valida",
      ko: "유효한 이메일 주소 입력"
    },
    enter_password: {
      en: "Enter Password",
      fr: "Entrez le mot de passe",
      de: "Passwort eingeben",
      ja: "パスワードを入力してください",
      es: "Introducir contraseña",
      it: "Inserisci la password",
      ko: "비밀번호 입력"
    },
    password_constraint_message: {
      en: "Password must contain at least 8 characters, including at least one letter and one number",
      fr: "Le mot de passe doit contenir au moins 8 caractères, y compris au moins une lettre et un chiffre",
      de: "Das Passwort muss mindestens 8 Zeichen enthalten, darunter mindestens ein Buchstabe und eine Zahl",
      ja: "パスワードは、少なくとも1つの文字と1つの数字を含む8文字以上である必要があります",
      es: "La contraseña debe contener al menos 8 caracteres, incluyendo al menos una letra y un número",
      it: "La password deve contenere almeno 8 caratteri, inclusa almeno una lettera e un numero",
      ko: "비밀번호는 문자와 숫자를 적어도 하나씩 포함하여 8자 이상이어야 합니다"
    },
    password: {
      en: "Password",
      fr: "Mot de passe",
      de: "Passwort",
      ja: "パスワード",
      es: "Contraseña",
      it: "Parola d'ordine",
      ko: "암호"
    },
    confirm_password: {
      en: "Confirm Password",
      fr: "Confirmez le mot de passe",
      de: "Passwort bestätigen",
      ja: "パスワードを確認",
      es: "Confirmar contraseña",
      it: "Conferma password",
      ko: "비밀번호 확인"
    },
    already_have_an_account: {
      en: "Already have an account?",
      fr: "Vous avez déjà un compte?",
      de: "Haben Sie bereits ein Konto?",
      ja: "すでにアカウントをお持ちですか？",
      es: "¿Ya tienes una cuenta?",
      it: "Hai già un account?",
      ko: "이미 계정이 있습니까?"
    },
    login: {
      en: "Login",
      fr: "S'identifier",
      de: "Anmeldung",
      ja: "ログイン",
      es: "Iniciar sesión",
      it: "Accesso",
      ko: "로그인"
    },
    register: {
      en: "Register",
      fr: "S'inscrire",
      de: "Registrieren",
      ja: "登録",
      es: "Registrarse",
      it: "Registrare",
      ko: "등록"
    },
    if_you_have_account: {
      en: "If you have account",
      fr: "Si vous avez un compte",
      de: "Wenn Sie ein Konto haben",
      ja: "アカウントをお持ちの場合",
      es: "Si tienes cuenta",
      it: "Se hai un account",
      ko: "계정이 있으면"
    },
    invalid_username_password: {
      en: "Invalid username or password",
      fr: "Nom d'utilisateur ou mot de passe invalide",
      de: "Ungültiger Benutzername oder Passwort",
      ja: "無効なユーザー名またはパスワード",
      es: "Nombre de usuario o contraseña inválidos",
      it: "Nome utente o password non validi",
      ko: "잘못된 사용자 이름 또는 비밀번호"
    },
    password_not_match: {
      en: "Password does not match",
      fr: "Le mot de passe ne correspond pas",
      de: "Passwort stimmt nicht überein",
      ja: "パスワードが一致しません",
      es: "La contraseña no coincide",
      it: "La password non corrisponde",
      ko: "비밀번호가 일치하지 않습니다"
    },
    email: {
      en: "Email",
      fr: "Email",
      de: "Email",
      ja: "Eメール",
      es: "Correo electrónico",
      it: "E-mail",
      ko: "이메일"
    },
    please_input_your_email: {
      en: "Please input your email",
      fr: "Veuillez saisir votre e-mail",
      de: "Bitte geben Sie Ihre E-Mail-Adresse ein",
      ja: "メールアドレスを入力してください",
      es: "Por favor ingrese su correo electrónico",
      it: "Inserisci la tua email",
      ko: "이메일을 입력하십시오"
    },
    please_input_your_password: {
      en: "Please input your password",
      fr: "Veuillez saisir votre mot de passe",
      de: "Bitte geben Sie Ihr Passwort ein",
      ja: "パスワードを入力してください",
      es: "Por favor ingrese su contraseña",
      it: "Inserisci la tua password",
      ko: "비밀번호를 입력하십시오"
    },
    please_input_your_first_name: {
      en: "Please input your first name",
      fr: "Veuillez saisir votre prénom",
      de: "Bitte geben Sie Ihren Vornamen ein",
      ja: "名を入力してください",
      es: "Por favor ingrese su nombre",
      it: "Inserisci il tuo nome",
      ko: "이름을 입력하십시오"
    },
    please_input_your_last_name: {
      en: "Please input your last name",
      fr: "Veuillez saisir votre nom de famille",
      de: "Bitte geben Sie Ihren Nachnamen ein",
      ja: "苗字を入力してください",
      es: "Por favor ingrese su apellido",
      it: "Inserisci il tuo cognome",
      ko: "성을 입력하십시오"
    },
    please_input_your_password_again: {
      en: "Please input your password again",
      fr: "Veuillez saisir à nouveau votre mot de passe",
      de: "Bitte geben Sie Ihr Passwort erneut ein",
      ja: "もう一度パスワードを入力してください",
      es: "Por favor ingrese su contraseña nuevamente",
      it: "Inserisci nuovamente la tua password",
      ko: "비밀번호를 다시 입력하십시오"
    },
    password_is_required: {
      en: "Password is required",
      fr: "Le mot de passe est requis",
      de: "Passwort ist erforderlich",
      ja: "パスワードが必要です",
      es: "Se requiere contraseña",
      it: "La password è obbligatoria",
      ko: "비밀번호가 필요합니다"
    },
    name_is_required: {
      en: "Name is required",
      fr: "Le nom est requis",
      de: "Name ist erforderlich",
      ja: "名前が必要です",
      es: "Se requiere nombre",
      it: "Il nome è obbligatorio",
      ko: "이름이 필요합니다"
    },
    please_input_your_username: {
      en: "Please input your username",
      fr: "Veuillez saisir votre nom d'utilisateur",
      de: "Bitte geben Sie Ihren Benutzernamen ein",
      ja: "ユーザー名を入力してください",
      es: "Por favor ingrese su nombre de usuario",
      it: "Inserisci il tuo nome utente",
      ko: "사용자 이름을 입력하십시오"
    },
    username: {
      en: "Username",
      fr: "Nom d'utilisateur",
      de: "Benutzername",
      ja: "ユーザー名",
      es: "Nombre de usuario",
      it: "Nome utente",
      ko: "사용자 이름"
    },
    if_problem_persists_contact: {
      en: "If problem persists, please contact support.",
      fr: "Si le problème persiste, veuillez contacter le support.",
      de: "Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.",
      ja: "問題が解決しない場合は、サポートにお問い合わせください",
      es: "Si el problema persiste, comuníquese con el soporte.",
      it: "Se il problema persiste, contattare il supporto.",
      ko: "문제가 지속되면 지원팀에 문의하십시오."
    },
    activation_link_expired: {
      en: "Activation link expired",
      fr: "Lien d'activation expiré",
      de: "Aktivierungslink abgelaufen",
      ja: "アクティベーションリンクの有効期限が切れました",
      es: "Vínculo de activación caducado",
      it: "Link di attivazione scaduto",
      ko: "활성화 링크 만료"
    },
    your_account_is_activated: {
      en: "Your account is activated",
      fr: "Votre compte est activé",
      de: "Ihr Konto ist aktiviert",
      ja: "アカウントが有効になりました",
      es: "Tu cuenta está activada",
      it: "Il tuo account è attivato",
      ko: "계정이 활성화되었습니다"
    },
    login_and_start_sharing: {
      en: "Login and start sharing",
      fr: "Connectez-vous et commencez à partager",
      de: "Anmelden und teilen",
      ja: "ログインして共有を開始",
      es: "Inicia sesión y comienza a compartir",
      it: "Accedi e inizia a condividere",
      ko: "로그인하고 공유 시작"
    },
    chat: {
      en: "Chat",
      fr: "Discussion",
      de: "Chat",
      ja: "チャット",
      es: "Charla",
      it: "Chiacchierata",
      ko: "잡담"
    },
    activation_could_not_complete: {
      en: "Activation could not complete",
      fr: "L'activation n'a pas pu être complétée",
      de: "Aktivierung konnte nicht abgeschlossen werden",
      ja: "アクティベーションを完了できませんでした",
      es: "La activación no pudo completarse",
      it: "L'attivazione non ha potuto completarsi",
      ko: "활성화를 완료할 수 없습니다"
    },

  }
];

export function convert_to_i18n_format(aeonx_format_data) {
  const new_data = {};
  for (let k in aeonx_format_data) {
    const tdata = aeonx_format_data[k] || {};

    for (let key in tdata) {
      const key_data = tdata[key]; // key = 818n key
      for (let lang in key_data) {
        const val = key_data[lang] || "";
        if (!val.length) {
          continue;
        }

        if (new_data[lang] === undefined) {
          new_data[lang] = { translation: {} };
        }

        new_data[lang].translation[key] = val;
      }
    }
  }
  return new_data;
}

const translation_data = convert_to_i18n_format(data);

export function get_translation_data() {
  return translation_data;
}
