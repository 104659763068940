import { Button, Col, Row } from "antd";
import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";

import { LeftSquareFilled, RightSquareFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { previewState } from "../../__GlobalStates__";
import logo from "../../resources/logo.webp";
import { resetTokenCache } from "../../Auth";

const logoSrc = logo;

export default function TopBar() {
  const [isPreviewOpen, setIsPreviewOpen] = useRecoilState(previewState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    setIsChatOpen(pathname.includes("/app/chat"));
  }, [window.location.pathname]);

  const handleClickFileLibrary = () => {
    navigate("/app/mydb");
  }


  const handleClickChatHistory = () => {
    navigate("/app/chat");
  }

  const handleSignOut = () => {
    resetTokenCache();
    navigate("/login");
  }

  return (
    <>
      <Header
        style={{
          height: "50px",
          width: "100%",
          backgroundColor: "#212121",
          color: "white",
          borderBottom: "1px solid #333",
        }}
      >
        <Row
          style={{
            height: "100%",
          }}
        >
          <Col span={4}>
            <img
              src={logoSrc}
              alt=""
              style={{
                paddingLeft: "15px",
                paddingTop: "0px",
                paddingBottom: "2px",
                height: "40px",
                width: "auto",
              }}
            />
          </Col>

          <Col span={14}></Col>

          <Col span={3}>
            {
              isChatOpen ?
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    style={{
                      marginBottom: "20px",
                      width: "auto",
                    }}
                    onClick={handleClickFileLibrary}
                  >
                    {t("knowledge_base")}
                  </Button>
                </div> :

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    style={{
                      marginBottom: "20px",
                    }}
                    onClick={handleClickChatHistory}
                  >
                    {t("chat_history")}
                  </Button>
                </div>

            }
          </Col>

          <Col span={2}>
            {
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Button
                  style={{
                    marginBottom: "20px",
                  }}
                  onClick={handleSignOut}
                >
                  {t("sign_out")}
                </Button>
              </div>
            }
          </Col>

          <Col span={1}>
            {
              isChatOpen &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {
                  isPreviewOpen ? <RightSquareFilled
                    size={20}
                    style={{
                      color: "white",
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "20px",
                    }}
                    onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                  /> :

                    <LeftSquareFilled
                      size={20}
                      style={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                        float: "right",
                        marginBottom: "20px",
                      }}
                      onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                    />}
              </div>}
          </Col>
        </Row>
      </Header>
    </>
  );
}
