import React, { useState, useEffect } from "react";
import { ButtonProps, Modal } from "antd";
import { useTranslation } from "react-i18next";
import konsole from "../../konsole";


export interface ModalContainerProps {
  title?: string;
  width?: number;
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  onCancel?: () => void;
  onFinish: any;
  destroyOnClose?: boolean;
  confirmLoading?: boolean;
  okText?: string;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  bodyStyle?: React.CSSProperties;
  children?: React.ReactNode;
  cancelButtonProps?;
  closable?: boolean;
  footer?: any;
}

export default function ModalContainer(props: ModalContainerProps) {
  const { t } = useTranslation();

  const onFinish = async (values) => {
    try {
      await props.onFinish();
    } catch (e) {
      konsole.log(e);
    }
  };
  const handleCancel = () => {
    props.setVisible(false);
    if (props.onCancel) props.onCancel();
  };

  let width = 550;
  if (props.width) {
    width = props.width;
  }

  const title = props.title ? t(props.title) : "";

  if (props.visible === true) {
    return (
      <Modal
        destroyOnClose={true}
        transitionName="fade"
        maskTransitionName="fade"
        className="model-container-wrapper"
        title={<span style={{ fontWeight: 550 }}> {title}</span>}
        open={props.visible}
        onCancel={handleCancel}
        width={width}
        onOk={onFinish}
        confirmLoading={props.confirmLoading}
        okText={props.okText ? props.okText : t(`save`)}
        okButtonProps={props?.okButtonProps}
        cancelText={props.cancelText ? props.cancelText : t(`cancel`)}
        maskClosable={false}
        style={props.bodyStyle}
        cancelButtonProps={props.cancelButtonProps}
        closable={props.closable === true}
        footer={props.footer}
      >
        {props.children}
      </Modal>
    );
  }

  return null;
}
