import light from "./blaze_light";
import dark from "./blaze_dark";
// import themeColor from "./blaze_colors.json";

export const THEMES = {
  light,
  dark,
};

export interface ITAG_COLORS {
  DOWNLOAD_PORTAL: string;
  EXTERNAL: string;
  INTERNAL: string;
  DELETED: string;
  EXPIRED: string;
  INACCESSIBLE: string;
}

export const TAG_COLORS: ITAG_COLORS = {
  DOWNLOAD_PORTAL: "#FF7A59",
  EXTERNAL: "#0078D4",
  INTERNAL: "green",
  DELETED: "red",
  EXPIRED: "orange",
  INACCESSIBLE: "purple",
};

