import _ from "lodash";
import {
  getPayload,
  PUBLIC_KEY,
  PUBLIC_XP_KEY
} from ".";
import { ALGORITHM, ISSUER as ISSUER1 } from "../Params";
import konsole from "../konsole";
import moment from "moment";
// import jwt from "jsonwebtoken";

const ISSUER: string[] = [ISSUER1 as string];
const ALGORITHMS: string[] = [ALGORITHM];

const jrsa = require("jsrsasign");
const { KEYUTIL, KJUR } = jrsa;


// export async function checkJWT_jwtlib(
//   token: string,
//   algorithms: string[] = ALGORITHMS,
//   issuer: string[] = ISSUER
// ) {
//   const now = Math.round(moment().utc().valueOf() / 1000);

//   try {
//     const payload = jwt.verify(token, PUBLIC_KEY, {
//       algorithms: algorithms,
//       issuer: issuer,
//       clockTimestamp: now, // Use clockTimestamp instead of expiredAt
//     });
//     return payload;
//   } catch (e) {
//     konsole.error("Invalid token: ", e);
//   }

//   try {
//     return getPayload();
//   } catch (e) {
//     konsole.error("could not renew token", e);    
//   }
//   throw new Error("Invalid token");
// }






// export function checkJWT_browser_only(
export function checkJWT_jwtlib(
  token: string,
  algorithms: string[] = ALGORITHMS,
  issuer: string[] = ISSUER
) {
  let xpkey: any = PUBLIC_XP_KEY;

  // if (typeof public_key === "string") {
  //   xpkey = getKeyObject(public_key);
  // } else {
  //   xpkey = public_key;
  // }

  const now = Math.round(moment().utc().valueOf() / 1000);
  const isValid = KJUR.jws.JWS.verifyJWT(token, xpkey, {
    alg: algorithms,
    iss: issuer,
    // verifyAt: KJUR.jws.IntDate.getNow(),
    verifyAt: now,
  });

  // console.log("isValid", isValid);
  // console.log("isValid", xpkey);
  // console.log("isValid", token);
  // console.log("isValid", algorithms);
  // console.log("isValid", issuer);
  // console.log("isValid", now);

  // if (isValid === false) {
  //   konsole.log("Invalid token, ignoring it for a while");
  //   throw new Error("Invalid token");
  // }

  const [_header, _metadata, _signature] = token.split(".");
  const payload = KJUR.jws.JWS.readSafeJSONString(jrsa.b64utoutf8(_metadata));

  konsole.log("payload", payload);

  if (_.size(payload) === 0) {
    throw new Error(`access_token could not be decoded ${token}`);
  }

  const exp = payload.exp;
  const iss = payload.iss;
  const alg = payload.alg;
  const iat = payload.iat;

  if (now > exp) {
    throw new Error("Invalid token");
  }
  return payload;
}
