import { getMainAxiosInstance } from ".";
import konsole from "../konsole";
import { config_json, prepare_query, prepare_url } from "../utils";

export async function get_chats({ _id = null }) {
  const query: any = prepare_query(
    {
      _id
    },
    true
  );

  const url = prepare_url("get_chats", query);

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.get(url);
    if (result && result.data && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}



export async function get_messages_llm({ chat_id }) {
  const query: any = prepare_query(
    {
      chat_id
    },
    true
  );

  const url = prepare_url("get_messages_llm", query);

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.get(url);
    if (result && result.data && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}


export async function get_chats_of_user({ _id = null, organization_id }) {
  const query: any = prepare_query(
    {
      _id,
      organization_id,
    },
    true
  );

  const url = prepare_url("get_chats_of_user", query);

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.get(url);
    if (result && result.data && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}

export async function create_chat({ name = null }) {
  const query = prepare_query({
    name,
  });

  const url = prepare_url("create_chat");

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, query, config_json);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}

export async function update_chat({ _id, hash_value}) {
  const query = prepare_query({
    _id,
    hash_value
  });

  const url = prepare_url("update_chat");


  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, query, config_json);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return false;
}


export async function assign_file_to_chat({ chat_id, file_id }) {
  const query = prepare_query({
    chat_id,
    file_id
  });

  const url = prepare_url("assign_file_to_chat");

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, query, config_json);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return false;
}




export async function remove_file_from_chat({ chat_id, file_id }) {
  const query = prepare_query({
    chat_id,
    file_id
  });

  const url = prepare_url("remove_file_from_chat");

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, query, config_json);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return false;
}



export async function create_message_llm({ chat_id, file_ids=[], content, is_knowledge_base_active}) {
  const query = prepare_query({
    chat_id,
    file_ids,
    content,
    is_knowledge_base_active
  });

  const url = prepare_url("create_message_llm");

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, query, config_json);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return false;
}


export async function delete_chat({ _id, organization_id }) {
  const query = prepare_query({ _id, organization_id });

  if (!query) {
    throw "company _id is not provided, no request sent";
  }

  const url = prepare_url("delete_chat");

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.delete(url, { data: query, ...config_json });
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
    throw `Could not delete: ${e}`;
  }
  return false;
}




export async function upload_llm_file({ file
}) {

  if (!file) {
    return {}
  }

  const query: any = prepare_query({}, true)
  const url = prepare_url("upload_llm_file", query)

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.post(url, file, config)
    
    if (result && result.data) {
      return result.data
    }
  } catch (e: any) {
    konsole.log(e)
  }
  return
}




export async function get_all_llm_files({ organization_id=null }) {
  const query: any = prepare_query(
    {
      organization_id,
    },
    true
  );

  const url = prepare_url("get_all_llm_files", query);

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.get(url);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}


export async function get_chat_files({ chat_id, organization_id=null, include_url=false }) {
  const query: any = prepare_query(
    {
      chat_id,
      organization_id,
      include_url
    },
    true
  );

  const url = prepare_url("get_chat_files", query);

  try {
    const axios_instance = getMainAxiosInstance();
    const result = await axios_instance.get(url);
    if (result && result.data) {
      return result.data;
    }
  } catch (e) {
    konsole.log(e);
  }
  return [];
}