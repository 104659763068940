import axios from "axios";
import konsole from "../konsole";
import qs from "qs";
import {
  getRefreshToken,
  getUserId,
  setAccessTokenCache,
  setRefreshTokenCache,
} from "../Auth";
import { BACKEND_API } from "../Params";
import { sleep } from "../utils";

export const REQUEST_TIMEOUT_DEFAULT: number = 5 * 60 * 1000; // 5 minutes
export const MAX_RETRY: number = 3;

export interface AxiosInstanceProps {
  baseURL: string;
  timeout?: number;
  headers?: any;
  refreshTokenFunc?: string;
  getAccessToken: Function;
  setAccessToken: Function;
  getRefreshToken: Function;
  setRefreshToken: Function;
}

export function getAxiosInstance(args: AxiosInstanceProps) {
  const instance = axios.create({
    baseURL: args.baseURL,
    timeout: args.timeout || REQUEST_TIMEOUT_DEFAULT,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use((request) => {
    if (
      request.data &&
      request.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      request.data = qs.stringify(request.data);
    }

    return request;
  });

  instance.interceptors.request.use(
    async (config) => {
      const access_token = await args.getAccessToken();
      if (access_token) {
        config.headers["Authorization"] = "Bearer " + access_token;
        config.headers["referrer"] = document.referrer;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      konsole.log("AXIOS Errror", error);
      const originalRequest = error.config;

      if (originalRequest["_retryCount"] === undefined) {
        originalRequest["_retryCount"] = 0;
      }

      if (
        originalRequest["_retryCount"] < MAX_RETRY &&
        error.response.status > 400 &&
        error.response.status < 410 //&&
      ) {
        originalRequest._retry = true;
        originalRequest["_retryCount"] += 1;
        try {
          const access_token = await renew_my_current_access_token();
          originalRequest.headers["Authorization"] = "Bearer " + access_token;
          originalRequest.headers["referrer"] = document.referrer;
          return await instance(originalRequest);
        } catch (e) {
          konsole.error("Error renewing token", e);
          await sleep(100);
        }
        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

const IS_REFFRESHING = [false];
const RENEW_TOKEN_Q = { data: {} };



export async function renew_my_current_access_token() {

  for (let i = 0; i < 100; i++) {
    if (IS_REFFRESHING[0] === false) {
      RENEW_TOKEN_Q.data = {};
      break;
    }

    if (Object.keys(RENEW_TOKEN_Q.data).length > 0) {
      return RENEW_TOKEN_Q.data["access_token"] ?? "";
    }
    await sleep(200);
  }

  IS_REFFRESHING[0] = true;

  try {
    const current_refresh_token = (await getRefreshToken()) || "";
    const user_id = getUserId() ?? "";

    if (user_id.length === 0) {
      throw new Error("Invalid user id");
    }

    if (
      !current_refresh_token ||
      current_refresh_token === "undefined" ||
      current_refresh_token.length === 0
    ) {
      throw new Error("Invalid refresh token");
    }

    const response_data = await fetch(`${BACKEND_API}/auth/refresh_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: current_refresh_token ?? "",
        user_id: user_id ?? "",
      }),
    });

    const body = await response_data.json();
    const accessToken = body["access_token"];
    const refreshToken = body["refresh_token"];
    await setAccessTokenCache(accessToken);
    await setRefreshTokenCache(refreshToken);

    RENEW_TOKEN_Q.data = {
      access_token: accessToken,
      refresh_token: refreshToken,
    };
    return accessToken;
  } catch (e) {
    throw e;
  } finally {
    IS_REFFRESHING[0] = false;
  }
}
