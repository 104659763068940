import { Grid } from "antd";
import { isMobile } from "react-device-detect";


const { useBreakpoint } = Grid;


export const useLargeScreen = () => {
    const { sm } = useBreakpoint();
    // konsole.log(" useBreakpoint()", useBreakpoint());
  
    if (isMobile) {
      return false;
    }
  
    return sm;
  };
