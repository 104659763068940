import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./efffects.scss";
import { RecoilRoot } from "recoil";
import { App as App0, ConfigProvider } from "antd";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App0>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </App0>
  </React.StrictMode>
);
