import { Button, Layout, Menu } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  hideSideMenuState,
  mainViewCenterXState,
  selectedChatWorkspaceState,
  siderCollapsedState,
  siderMenuRectState,
} from "../../__GlobalStates__";

import type { MenuProps } from "antd";
import { isMobile } from "react-device-detect";
import { get_chats } from "../../Helpers/chats";
import { theme } from "../../Store/theme";
import { useLargeScreen } from "../../__GlobalStates__/hooks";
import { WindowRect, getRect } from "../../utils";
import { useOutsideAlerter } from "../customHooks/useOutsideAlerter";
import CreateNewChatModal from "../modals/CreateNewChatModal";
import { getMenuItem } from "./helper";
import "./style.scss";

const { Sider } = Layout;

const subMenuKeys = [
  "/planograms",
  "/settings",
  "/analytics",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
  "sub9",
  "sub10",
  "sub11",
  "sub12",
];

export default function NavigationSideMenu(props) {
  const { t } = useTranslation();
  const location = useLocation();
  let submenu_selected: any = subMenuKeys.find(
    (x) => x === String(location.pathname).toLocaleLowerCase()
  );
  const [openKeys, setOpenKeys] = useState(
    submenu_selected ? [submenu_selected] : []
  );

  const [selectedChatWorkspace, setSelectedChatWorkspace] = useRecoilState(
    selectedChatWorkspaceState
  );

  const [hideSideMenu, setHideSideMenu] = useRecoilState(hideSideMenuState);
  const [collapsed, setCollapsed] = useRecoilState(siderCollapsedState);
  const largeScreen = useLargeScreen();

  const [createChatModalVisible, setCreateChatModalVisible] = useState(false);
  const [chatWorkspaces, setChatWorkspaces] = useState([] as any);

  const navigate = useNavigate();
  const _theme: any = useRecoilValue(theme);

  const pathname = location.pathname;


  const [sideMenuRect, setSideMenuRect] = useRecoilState<
    WindowRect | undefined
  >(siderMenuRectState);
  const [mainMenuCenterX, setMainMenuCenterX] =
    useRecoilState(mainViewCenterXState);

  const [orgInitials, setOrgInitials] = useState("");
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, (event) => {
    if (isMobile === true && largeScreen === false) {
      setCollapsed(true);
    }
  });

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => openKeys.indexOf(key) === -1);

    if (subMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  useEffect(() => {
    const el = document.getElementById("navigation-sidemenu");
    const rect = getRect(el);
    const el2 = document.getElementById("the-main-content");
    const rect2 = getRect(el2);

    if (rect && rect.height > 0 && rect.width > 0) {
      setSideMenuRect(rect);

      if (rect2 && rect2.height > 0 && rect2.width > 0) {
        const center_x = rect.width + rect2.width / 2;
        setMainMenuCenterX(center_x);
      }
    }
  }, [
    selectedChatWorkspace,
    collapsed,
    orgInitials,
    setMainMenuCenterX,
    setSideMenuRect,
  ]);


  useEffect(() => {
    refreshChats()
  }, []);

  const handleManageChat = (e, key) => {
    e.stopPropagation(); // Prevent triggering the main menu item click
    navigate(`/app/mychatdb?${key}`); // Define your redirection logic here
  };

  const base_menu_items: MenuProps["items"] = chatWorkspaces.map((chatWorkspace) => {
    return getMenuItem({
      id: chatWorkspace._id,
      label: (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>{chatWorkspace.label}</span>
          <span
            className="menu-extra"
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={(e) => handleManageChat(e, chatWorkspace.key)}
          >
            ...
          </span>
        </div>
      ),
      key: chatWorkspace.key,
    })
  })


  var menu_items = base_menu_items;

  const onClick: MenuProps["onClick"] = (e) => {
    setSelectedChatWorkspace(chatWorkspaces.find((x) => x.key === e.key));
    navigate(pathname + "?" + e.key);
  };

  const collapsedWidth = !largeScreen ? "0" : undefined;
  const bgColor = _theme === "light" ? "white" : "#171717"
  const color = _theme === "light" ? "black" : "white"


  const handleCreateChat = () => {
    setCreateChatModalVisible(true)
  }


  const refreshChats = async () => {
    const result = await get_chats({});
    const resultData = result?.data;

    if (result?.success && resultData) {
      const chatsData = resultData.map((chat: any) => ({
        _id: chat["_id"],
        label: chat["name"],
        key: chat["_id"],
      }));
      setChatWorkspaces(chatsData);

      if (Object.keys(selectedChatWorkspace || {}).length === 0) {
        setSelectedChatWorkspace(chatsData[0]);
      }
    }
  }

    return (
      <>
        <Sider
          id="navigation-sidemenu"
          ref={wrapperRef}
          className="sider-shadow"
          hidden={hideSideMenu}
          theme={_theme}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(!value)}
          breakpoint={largeScreen ? "lg" : undefined}
          collapsedWidth={collapsedWidth}
          trigger={null}
          style={{
            position: largeScreen ? "unset" : "fixed",
            zIndex: 99,
            height: largeScreen ? "inherit" : "100vh",
            backgroundColor: bgColor,
            color: color,
          }}
        >

          <div
            style={{
              margin: "10px 20px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            <Button
              onClick={handleCreateChat}
            >
              {t("create_chat")}
            </Button>
          </div>

          {
            selectedChatWorkspace?.label && (
              <div
                style={{
                  margin: "10px 20px",
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${color}`,
                  borderRadius: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px", fontWeight: "bold",

                  }}
                >
                  {selectedChatWorkspace?.label}
                </div>
              </div>
            )
          }

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Menu
              theme={_theme}
              mode="inline"
              defaultSelectedKeys={["1"]}
              selectedKeys={[location.pathname]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={menu_items}
              onClick={onClick}
            />


            {
              createChatModalVisible && (
                <CreateNewChatModal
                  visible={createChatModalVisible}
                  setVisible={setCreateChatModalVisible}
                  refresh={refreshChats}
                />
              )
            }
          </div>
        </Sider>
      </>
    );
  }
