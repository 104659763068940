import { ThemeConfig } from "antd";
import { atom, selector } from "recoil";
import { THEMES } from "../Theme";
import { isMobile } from "react-device-detect";

export const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const selectedFolderState = atom<string>({
  key: "selectedFolderState",
  default: "",
  effects: [],
});

export interface SelectedFileStateProps {
  [selectedFolder: string]: string  
}

export const base_selectedFileState = atom<SelectedFileStateProps>({
  key: "base_selectedFileState",
  default: {},  
  effects: [],
});


export const selectedFileState = selector<string>({
  key: "selectedFileState",
  get: ({ get }) => {
    const selectedFolder = get(selectedFolderState);
    const baseSelectedFileState = get(base_selectedFileState);
    return baseSelectedFileState[selectedFolder] ;
  },
  set: ({ set, get }, newValue) => {
    const selectedFolder = get(selectedFolderState);
    const new_file = {
      ...get(base_selectedFileState),
      [selectedFolder]: newValue
    } as SelectedFileStateProps;
    set(base_selectedFileState, new_file);
      
  },
}); // This is a placeholder for the actual implementation


export const selctedThemeState = atom<ThemeConfig>({
  key: "selctedThemeState",
  default: THEMES["light"],
  effects: [localStorageEffect("sm_selected_theme")],
});




export const base_siderCollapsedState = atom<boolean>({
  key: "base_siderCollapsedState",
  default: isMobile ? true : false,
});

export const siderCollapsedState = selector<boolean>({
  key: "siderCollapsedState",
  get: ({ get }) => {
    return get(base_siderCollapsedState);
  },
  set: ({ set }, newValue: any) => {
    set(base_siderCollapsedState, newValue);
  },
});



export const previewState = atom<boolean>({
  key: "previewState",
  default: true,
});


export const selectedFileToPreviewState = atom<any>({
  key: "selectedFileToPreviewState",
  default: null,
});


export const base_selectedOrganizationState = atom<{}>({
  key: "base_selectedOrganizationState",
  default: {},
});


export const selectedOrganizationState = selector<any>({
  key: "selectedOrganizationState",
  get: ({ get }) => {
    return get(base_selectedOrganizationState);
    },
  set: ({ set }, newValue: any) => {
    set(base_selectedOrganizationState, newValue);
  },
});




export const base_chatWorkspaceState = atom<{}>({
  key: "base_chatWorkspaceState",
  default: {},
});


export const selectedChatWorkspaceState = selector<any>({
  key: "selectedChatWorkspaceState",
  get: ({ get }) => {
    return get(base_chatWorkspaceState);
    },
  set: ({ set }, newValue: any) => {
    set(base_chatWorkspaceState, newValue);
  },
});


export const base_knowledgeBaseActiveState = atom<{}>({
  key: "base_knowledgeBaseActiveState",
  default: {},
});


export const selectedknowledgeBaseActiveState = selector<any>({
  key: "selectedknowledgeBaseActiveState",
  get: ({ get }) => {
    return get(base_knowledgeBaseActiveState);
    },
  set: ({ set }, newValue: any) => {
    set(base_knowledgeBaseActiveState, newValue);
  },
});


export const base_hideSideMenuState = atom<boolean>({
  key: "base_hideSideMenuState",
  default: false,
});

export const hideSideMenuState = selector<boolean>({
  key: "hideSideMenuState",
  get: ({ get }) => {
    return get(base_hideSideMenuState);
  },
  set: ({ set }, newValue: any) => {
    set(base_hideSideMenuState, newValue);
  },
});



export const siderMenuRectState = atom<any | undefined>({
  key: "siderMenuWidthState",
  default: undefined,
});



export const mainViewCenterXState = atom<any | undefined>({
  key: "mainViewCenterXState",
  default: undefined,
});


