import { Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create_chat } from '../../Helpers/chats';
import { ZERO_ID } from '../../Params';
import konsole from '../../konsole';
import ModalContainer from './ModalContainer';



export default function CreateNewChatModal(props) {

    const [form] = Form.useForm()
    const { t } = useTranslation();
    const [confirmLoading, setConfirmLoading] = useState(false)


    const onFinish = async values => {
        try {
            setConfirmLoading(true)
            const values = await form.validateFields()
            const _values: any = {...values} 
            const resp = await create_chat(_values)
            props.refresh()
            if(resp && resp.success) {
                konsole.log(`success while creating chat`)
            }else{
                konsole.log(`failure while creating chat`)
            }
            
            setConfirmLoading(false)
            props.setVisible(false)
        } catch (e) {
            setConfirmLoading(false)
            konsole.log(e)
        }
    }


    return (
        <ModalContainer
            visible={props.visible}
            setVisible={props.setVisible}
            title={t('create_new_chat')}
            onFinish={onFinish}
            okText= {`${t('create')}`}
            confirmLoading={confirmLoading}
        >
            <Form
                form={form}
                name="complex-form"
                labelCol={{ span: 8 }}
                labelAlign='left'
                wrapperCol={{ span: 16 }}
                size='small'
                preserve={false}
            >

                <br/>
                <Form.Item label={t("name")} style={{ marginBottom: 12 }}>
                    <Form.Item
                        name="name"
                        noStyle
                        rules={[{ type: 'string', required: true, message: `${t('name_is_required')}` }]}
                    >
                        <Input
                            style={{ width: 310 }}
                            placeholder={t("chat_name")}
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </ModalContainer>
    )
}