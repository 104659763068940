export const BACKEND_API = process.env.REACT_APP_BACKEND_API ?? "http://localhost:28114";
export const ISSUER = process.env.REACT_APP_ISSUER;
export const HOST: string = process.env.REACT_APP_FRONTEND_HOST ?? "";
export const HOST_HTTP_PREFIX = HOST.includes("localhost") ? "http://" : "https://";
export const HOST_TXT_PREFIX = "_blaze_verifier";

export const PUBLIC_KEY_URL = BACKEND_API + "/public/public-key.txt";
export const BLAZE_PUBLIC_KEY_URL = BACKEND_API + "/auth/get_blaze_public_key";
export const ALGORITHM = process.env.REACT_APP_ALGORITHM || "RS256";

export const AUTH_TOKEN_KEY = "blaze_auth";
export const ACCESS_TOKEN_KEY = "b_at";
export const REFRESH_TOKEN_KEY = "b_rt";
export const AUTH_STATUS_KEY = "blaze_auth_status";
export const API_VERSION = "/api/v1.0";

export const DS_CACHE_KEY = "blaze_auth_data";
export const DS_CACHE_KEY_LANG = `${DS_CACHE_KEY}-lang`;
export const DS_CACHE_KEY_DATE_FORMAT = `${DS_CACHE_KEY}-date_format`;
export const MAX_UPLOAD_URLS_LENGTH= parseInt(process.env.REACT_APP_MAX_UPLOAD_URLS_LENGTH || "10")

export const LLM_API_URL = BACKEND_API + API_VERSION;

export const ENCRYPTION_CODE_KEY = "b_ec";
export const ZERO_ID = "000000000000000000000000";




// let IS_AUTHENTICATED = false;

export function isAuthenticated() {
 return window.localStorage.getItem("n_ax") === "true";
} 

export function setIsAuthenticated(value) {    
    window.localStorage.setItem("n_ax", value);
}
