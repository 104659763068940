import store from "./secured_store";
import { atom, selector, useRecoilState } from "recoil";

type theme_type = "light" | "dark" | undefined

export const theme = atom({
    key: "theme",
    default: "dark"
})

export const toggleTheme = selector({
    key: "toggleTheme",
    get: ({ get }) => {
        const t = get(theme)
        if (t === "dark") {
            return "light"
        } else {
            return "dark"
        }
    }
})

