import { createBrowserRouter } from "react-router-dom";
import AppPage from "../AppPage";
import { DataPage } from "../DataPage";
import MainSearchPage from "../MainSearchPage";
import Page404 from "../Page404";
import RootPage from "../RootPage";

export const root_routes = {
  path: "/",
  element: <RootPage />,
  children: [
    {
      path: "",
      index: true,
      async lazy() {
        const { MyRoot } = await import("../RootPage/MyRoot");
        return { Component: MyRoot };
      },
    },
    {
      path: "login",
      index: true,
      async lazy() {
        const { LoginPage } = await import("../RootPage/LoginPage");
        return { Component: LoginPage };
      },
    },
    {
      path: "register",
      index: true,
      async lazy() {
        const { SignUpPage } = await import("../RootPage/RegisterPage");
        return { Component: SignUpPage };
      },
    },
    {
      path: "activate_account",
      index: true,
      async lazy() {
        const { ActivateAccount } = await import("../RootPage/ActivateAccount");
        return { Component: ActivateAccount };
      },
    }

  ],
};

export const app_routes = {
  path: "/app",
  element: <AppPage />,
  children: [
    {
      path: "chat",
      index: true,
      async lazy() {
        const { ChatPage } = await import("../AppPage/ChatPage");
        return { Component: ChatPage };
      },
    },
    {
      path: "dashboard",
      index: true,
      async lazy() {
        const { Dashboard } = await import("../AppPage/Dashboard");
        return { Component: Dashboard };
      },
    },
    {
      path: "main",
      index: true,
      async lazy() {
        const { MainPage } = await import("../AppPage/MainPage");
        return { Component: MainPage };
      },
    },
    {
      path: "mydb",
      index: true,
      async lazy() {
        const { MyDatabase } = await import("../DataPage/MyDatabase");
        return { Component: MyDatabase };
      },
    },
    {
      path: "mychatdb",
      index: true,
      async lazy() {
        const { ChatDatabase } = await import("../DataPage/ChatDataBase");
        return { Component: ChatDatabase };
      },
    },
  ],
};

const db_route = {
  path: "/db",
  element: <DataPage />,
  children: [
    {
      path: "mydb",
      index: true,
      async lazy() {
        const { MyDatabase } = await import("../DataPage/MyDatabase");
        return { Component: MyDatabase };
      },
    },
  ],
};

const search_route = {
  path: "/search",
  element: <MainSearchPage />,
  children: [
    {
      path: "main",
      index: true,
      async lazy() {
        const { SearchPage } = await import("../MainSearchPage/SearchPage");
        return { Component: SearchPage };
      },
    },
  ],
};

export const catch_all_routes = {
  path: "*",
  element: <Page404 />,
};

export const ROUTES = createBrowserRouter(
  [root_routes, app_routes, db_route, catch_all_routes, search_route],
  { basename: "/" }
);
